@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@100;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap" rel="stylesheet);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600&display=swap);
@font-face {
  font-family: 'BestermindRegular';
  src: url(/static/media/BestermindRegular.e58a1922.ttf); 
  src: url(/static/media/BestermindRegular.e58a1922.ttf) format('embedded-opentype'), 
       url(/static/media/BestermindRegular.e58a1922.ttf)  format('truetype'), 
}

* {
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}


/* scrollBar */

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
::-webkit-scrollbar-track {
    background: #555;
}
::-webkit-scrollbar-thumb {
    background: #555;
  border-radius: 10px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

img{
  -webkit-touch-callout: none; 
    -webkit-user-select: none;
        -ms-user-select: none; 
            user-select: none; 

}


@media screen and (max-width: 400px) {
  body {
    overflow-x: hidden;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
    body {
      overflow-x: hidden;
    }
}

.navbar {
    height: 6.5rem;
    position: absolute;
    background-color: transparent;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.navbar--container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 3rem;
    margin-top: 1rem;
}

.navbar--container > h1 {
    font-family: 'BestermindRegular';
    color: var(--primary-2);
    font-size: 2.5rem;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.navLink--container {
    margin-top: 5rem;
}

.drawer > .MuiBackdrop-root {
    background: rgba(33, 33, 33, 0.15) !important;
    -webkit-backdrop-filter: blur(20px) !important;
            backdrop-filter: blur(20px) !important;
}


@media (max-width:1100px) {
    .navbar--container{
        padding: 0 2rem;
    }
    .navbar--container > h1{
        font-size: 2.2rem;
    }
    .nav-menu{
        font-size: 2.2rem;
        margin-top: -1.5rem;
    }
}

@media (max-width:800px) {
    .navLink--container {
        margin-top: 3rem;
    }
}


@media (max-width:600px) {
    .nav-menu{
        color: var(--primary-2);
    }
    .navbar--container > h1{
        font-size: 2rem;
    }
    .nav-menu:hover{
        color: var(--primary-2);
    }
    .MuiDrawer-paper{
      border-radius: 0 !important;
    }
}


@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
    .navbar {
        height: 5rem;
    }
    .navbar--container {
        padding: 0 1rem;
        margin-top: 0rem;
    }
    .navbar--container > h1 {
        font-size: 1.5rem;
    }
}
.footer-container {
    background-color: #6579d0;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
.footer-container > h2 {
    font-size: 0.8cm;
    margin-bottom: 20px;
    color: #ffffff;
  }
  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
  }
  
  .footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
  }
  
  .footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
  }
  
  .footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
  }
  
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }
  
  .footer-link-wrapper {
    display: flex;
  }
  
  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
  }
  

  
  .footer-link-items > h2 {
    color: #fff;
  }
  
  .footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  
  .footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
  }
  
  .footer-email-form h2 {
    margin-bottom: 2rem;
  }
  
  .footer-input::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  
  .footer-input:-ms-input-placeholder {
    color: #b1b1b1;
  }
  
  .footer-input::placeholder {
    color: #b1b1b1;
  }
  
  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 24px;
  }
  
  .social-media {
    max-width: 1000px;
    width: 100%;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
  }
  
  .social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .website-rights {
    color: #fff;
    margin-bottom: 16px;
  }
  
  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }
  
  @media screen and (max-width: 768px) {
  }
  
  @media (max-width: 500px) {
    
 .footer-container > h2 {
      font-size: 0.55cm;
      margin-bottom: 20px;
      color: #ffffff;
    }   
  }

  @media (max-width: 290px) {
    
    .footer-container > h2 {
         font-size: 0.44cm;
         margin-bottom: 20px;
         color: #ffffff;
       }   
     }
.landing {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing--container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}

.landing--img {
  --img-size: 400px;
  position: absolute ;
  left: 35%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: var(--img-size);
  height: var(--img-size);
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s;
}

.landing--container-left {
  flex: 35% 1;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.lcl--content {
  margin: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.landing--social {
  font-size: 35px;
  margin: 0 1rem;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.landing--social:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.landing--container-right {
  flex: 65% 1;
  height: 100%;
  background-color: #eaeaea;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.lcr--content {
  width: 45%;
  margin-right: 7rem;
  font-family: var(--primaryFont);
  font-style: normal;
}

.lcr--content h6 {
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: -0.85rem;
  opacity: 0.8;
}

.lcr--content h1 {
  font-weight: 600;
  font-size: 3.25rem;
  line-height: 110%;
  margin: 1rem 0;
}

.lcr--content p {
  margin-top: 1.45rem;
  font-weight: 500;
  font-size: 1.15rem;
  opacity: 0.7;
}

.lcr-buttonContainer {
  margin-top: 2rem;
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "var(--primaryFont)", sans-serif;
}

@media (max-width: 1100px) {
  .landing--img {
    --img-size: 350px;
  }
  .lcl--content {
    margin: 2rem;
  }

  .lcr--content h6 {
    font-size: 1.1rem;
  }

  .lcr--content h1 {
    font-size: 3rem;
  }

  .lcr--content p {
    margin-top: 0.3rem;
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 1rem;
  }

  .lcr-buttonContainer {
    margin-top: 1rem;
    flex-direction: column;
    width: 200px;
    height: 120px;
    align-items: flex-start;
  }
}

@media (max-width: 900px) {
  .landing--img {
    --img-size: 300px;
  }

  .landing--social {
    font-size: 30px;
  }
  .lcr--content {
    width: 60%;
    margin-top: 10%;
    margin-right: 6%;
  }

  .lcr-buttonContainer {
    margin-top: 1rem;
    flex-direction: column;
    width: 200px;
    height: 120px;
    align-items: flex-start;
  }
  .lcr-buttonContainer button:nth-child(2) {
    display: none;
  }
}

@media (max-width: 600px) {
  .landing--container {
    flex-direction: column;
    justify-content: space-between;
  }
  .landing--img {
    --img-size: 200px;
    left: 50%;
    top: 30%;
    border: 8px solid #eaeaea;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .landing--container-left {
    flex: initial;
    width: 100%;
    height: 30%;
  }
  .landing--social {
    display: none;
  }
  .landing--container-right {
    box-sizing: border-box;
    padding: 0 2rem;
    flex: initial;
    height: 70%;
    width: 100%;
    background-color: #eaeaea;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
  }
  .lcr--content {
    margin-top: 20%;
    margin-right: 0;
    margin-right: initial;
    width: 100%;
  }
  .lcr--content h6 {
    font-size: 1rem;
    text-align: center;
  }
  .lcr--content h1 {
    font-size: 2.5rem;
    text-align: center;
  }
  .lcr--content p {
    font-size: 0.95rem;
    text-align: center;
    margin: 0 auto;
    width: 90%;
  }
  .lcr-buttonContainer {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .lcr-buttonContainer button {
    margin-top: -20%;
  }
}

@media (max-width: 600px) {

  .landing {
    height: 105vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .lcr--content {
    margin-top: 30%;
  }
}
@media (max-width: 495px) {
  .landing {
    height: 154vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 444px) {
  .landing {
    height: 154vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 398px) {

.landing {
    height: 148vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .lcr--content {
    margin-top: 50%;
  }
}
@media (max-width: 344px) {
  .landing {
    height: 184vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 290px) {
  .landing {
    height: 184vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  
  
  .lcr--content {
    margin-top: 50%;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
  .landing {
    margin-top: 60%;
    height: 100%;
  }

  .landing--img {
    --img-size: 150px;
    left: 50%;
    top: 20%;
  }
}

.about {
    min-height: 100vh;
    position: relative;
}

.about .line-styling{
  position: absolute;
  top: 50px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  height: 50px;
  width: 85%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about .line-styling .style-circle{
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.about .line-styling .style-line{
  width: 95%;
  height: 5px;
  border-radius: 10px;
}

.about .line-styling{
  position: absolute;
  top: 50px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  height: 50px;
  width: 85%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about .line-styling .style-circle{
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.about .line-styling .style-line{
  width: 95%;
  height: 5px;
  border-radius: 10px;
}
.about-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-top: 8rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.about-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    flex: 0.5 1;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.about-description >h2 {
    margin-bottom: 40px;
    font-size: 3.5rem;
    font-family: var(--primaryFont);
}

.about-description > p {
    font-size: 1.25rem;
    color: #e0d9d9;
    font-family: var(--primaryFont);
}

.about-img {
    margin-top: 40px;
    pointer-events: none;
}

.about-img > img {
    width: 380px;
    flex: 0.5 1;
}

@media (min-width: 992px) and (max-width: 1380px) {

    .about-description > h2 {
      font-size: 3.3rem;
      margin-bottom: 30px;
    }
    .about-description > p {
      font-size: 1.15rem;
    }

    .about-img>img {
        width: 300px;
    } 
}

@media screen and (max-width: 992px){

  .about .line-styling{
    top: 20px;
  }

    .about-body {
        padding-top: 2rem;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-evenly;
    }

    .about-description{
        flex: 0.6 1;
        box-sizing: border-box;
        /* padding: 5% 10%; */
        padding: 20px;

    }

    .about-description > h2 {
        font-size: 3rem;
        margin-bottom: 20px;
    }

    .about-description > p {
        font-size: 1.1rem;
    }

    .about-img > img {
        width: 250px;
        flex: 0.4 1;
    }

}

@media screen and (max-width: 800px){
  .about .line-styling .style-circle{
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  .about-description > h2{
    align-self: center;
  }

}

@media screen and (max-width: 600px){

  .about .line-styling .style-circle{
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }
  .about .line-styling .style-line{
    width: 93%;
    height: 3px;
    border-radius: 10px;
  }

  .about-description > h2 {
    font-size: 2.5rem;
  }

    .about-description > p{
      font-size: 0.95rem;
    }

    .about-img > img {
        width: 200px;
    }
}

.blog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    padding: 2rem;
    overflow-x: hidden;
}


.blog--header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 1rem;
}


.blog--header h1 {
    margin-bottom: 40px;
    font-size: 3.5rem;
    font-family: var(--primaryFont);
}

.blog--body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blog--bodyContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    grid-gap: 4.5rem;
    gap: 4.5rem;
}

.blog--viewAll {
    width: 100%;
    margin-top: 1rem;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.blog--viewAll a button {
    outline: none;
    border: none;
    width: 150px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: inherit;
    border-radius: 45px;
    font-size: 1.05rem;
    font-family: var(--primaryFont);
    font-weight: 500;
    padding-left: 1.5rem;
    cursor: pointer;
}


@media (min-width: 992px) and (max-width: 1380px) {

}


@media screen and (max-width: 992px) {
    .blog--bodyContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .blog--header h1 {
        font-size: 3rem;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 600px) {
    .blog--header h1 {
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
    .blog--viewAll {
        position: relative;
        margin-top: 2.5rem;
    }
    .blog--viewAll a {
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);  
    }
}

@media screen and (max-width: 400px) {

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}
.singleBlog {
    height: 420px;
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: baseline;
    border-radius: 5px;
}

.singleBlog--image {
    height: 50%;
    width: 100%;
    overflow: hidden;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.singleBlog--image > img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transition: -webkit-transform 1s, -webkit-filter 2s ease-in-out;
    transition: transform 1s, filter 2s ease-in-out;
    transition: transform 1s, filter 2s ease-in-out, -webkit-transform 1s, -webkit-filter 2s ease-in-out;
    -webkit-filter: blur(1px);
            filter: blur(1px);
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}

.singleBlog:hover > .singleBlog--image > img {
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.singleBlog--body {
    padding: 1rem;
}

.singleBlog--body p {
    font-family: var(--primaryFont);
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 0.65rem;
}

.singleBlog--body h3 {
    font-family: var(--primaryFont);
    margin-bottom: 0.55rem;
    font-size: 19px;
    line-height: 115%;
}

.singleBlog--body h6 {
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 15px;
    line-height: 139%;
    font-weight: 500;
    opacity: 0.9;
}



@media (min-width: 992px) and (max-width: 1380px) {
    .singleBlog {
        height: 430px;
    }
}


@media screen and (max-width: 992px) {
    .singleBlog {
        height: 450px;
        background-color: rgb(171, 240, 240);
        margin: 2rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: baseline;
    }
}

@media screen and (max-width: 800px) {
    .singleBlog {
        height: 450px;
        margin: 1rem auto;
    }
}

@media screen and (max-width: 700px) {
    .singleBlog {
        height: 450px;
        margin: 1rem auto;
    }
}

@media screen and (max-width: 600px) {
    .singleBlog {
        height: 450px;
        width: 300px;
        margin: 1rem 0;
    }
    .singleBlog--image > img {
        -webkit-filter: blur(0);
                filter: blur(0);
    }
}

@media screen and (max-width: 320px) {
    .singleBlog {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
}


@media only screen and (device-width: 768px) {

}
.education {
    min-height: 100vh;
}

.education-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: hsla(117, 56%, 49%, 0.702);
}

.education-description {
    width: 100%;
    flex: 0.65 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 7%;
}

.education-description > h1{
    font-size: 3.5rem;
    font-family: var(--primaryFont);
    margin-bottom: 2rem;
}

.education-card {
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    justify-content: flex-start;
    width: 90%;
    height: 140px;
    padding: 1.5rem;
    border-radius: 20px;
    margin-bottom: 1.5rem;
    transition: background-color 200ms ease-in-out;
}

.educard-img {
    border-radius: 50%;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.educard-img > img {
    width: 40px;
}

.education-details{
    margin-left: 0.6rem;
}

.education-details > h6 {
    font-family: var(--primaryFont);
    font-size: 0.85rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.education-details > h4 {
    font-family: var(--primaryFont);
    font-size: 1.225rem;
    font-weight: 600;
}

.education-details > h5 {
    font-family: var(--primaryFont);
    font-size: 1.15rem;
    font-weight: 600;
}

.education-image {
    box-sizing: border-box;
    padding: 1rem;
    flex: 0.35 1;
    margin-top: 1rem;
    pointer-events: none;
}

.education-image > img {
    width: 100%;
}



@media (min-width: 992px) and (max-width: 1380px) {

}


@media screen and (max-width: 992px) {
    .education {
        min-height: 100%;
    }
    .education-image {
        display: none;
    }
    .education-description {
        flex: 1 1;
        margin: auto;
    }    
    .education-description > h1{
        font-size: 3.2rem;
    }
    .education-card {
        width: 100%;
        padding: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

@media screen and (max-width: 800px) {
    .education-description > h1{
        font-size: 3rem;
        margin-bottom: 2rem;
        align-self: center;
    }
    .education-description {
        padding: 1rem;
    }
    .education-card {
        padding: 1rem;
        margin-bottom: 1.5rem;
        height: 130px;
    }
    .educard-img {
        border-radius: 50%;
        width: 45px;
        height: 45px;
    }
    
    .educard-img > img {
        width: 30px;
    }
    .education-details > h6 {
        font-size: 0.85rem;
        margin-bottom: 0.45rem;
    }
    
    .education-details > h4 {
        font-size: 1.125rem;
        line-height: 126%;
    }
    
    .education-details > h5 {
        font-size: 1.05rem;
        line-height: 126%;
    }
}

@media screen and (max-width: 600px) {
    .education-description > h1{
        font-size: 2.5rem;
    }
    .education-details > h4 {
        font-size: 1.12rem;
        line-height: 126%;
    }
    
    .education-details > h5 {
        font-size: 0.85rem;
        line-height: 126%;
    }
}

@media screen and (max-width: 400px) {
    .education-details > h4 {
        font-size: 1rem;
        line-height: 126%;
    }
    
    .education-details > h5 {
        font-size: 0.8rem;
        line-height: 126%;
    }

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}


@media screen and (max-width: 290px) {
    
    
    .education-details > h4 {
        font-size: 0.8rem;
        line-height: 126%;
    }
    
    .education-details > h5 {
        font-size: 0.6rem;
        line-height: 126%;
    }

}
.contacts {
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
}


.contacts--img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 280px;
    pointer-events: none;
}

.contacts--container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2rem 6rem 3rem;
    width: 80%;
    height: 100%;
    margin-top: 2rem;
}

.contacts--container > h1 {
    font-family: var(--primaryFont);
    font-size: 3.5rem;
    margin-bottom: 2.5rem;

}

.contacts-body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.contacts-form {
    display: flex;
    flex: 0.4 1;
    width: 100%;
}

.contacts-form form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 100%;
}

.input-container {
    width: 100%;
}


.form-input {
    height: 50px;
    box-sizing: border-box;
    border-radius: 50px; 
    outline: none;
    margin-bottom: 2rem;
    padding: 0.7rem 0.7rem;
    border-radius: 20px;
    width: 100%; 
    resize: none;
}



.form-message {
    height: 150px;
    box-sizing: border-box;
    border-radius: 50px; 
    outline: none;
    margin-bottom: 2rem;
    padding: 0.7rem 0.7rem;
    border-radius: 20px;
    width: 100%;
    resize: none;
}



.submit-btn button {
    border: none;
    outline: none;
    width: 140px;
    height: 50px;
    border-radius: 50px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
}

.submit-btn button p {
    font-size: 16px;
    font-family: var(--primaryFont);
}

.submit-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}
.send-icon {
    font-size: 25px;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: translate(5px,-3px) rotate(-30deg);
            transform: translate(5px,-3px) rotate(-30deg);
}

.success-icon {
    font-size: 28px;
    transition: all 0.3s 0.8s ease-in-out;
}

.contacts-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    flex: 0.6 1;
    box-sizing: border-box;
    padding-left: 7rem;
    margin-top: 1.2rem;
}



.personal-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 2.5rem;
}

.personal-details p {
    margin-left: 1.5rem;
    font-size: 18px;
    line-height: 110%;
    font-weight: 500;
    font-family: var(--primaryFont);
    width: 45%;
    word-break: break-word;
}

.socialmedia-icons {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-top: 4rem;
}


@media (min-width: 992px) and (max-width: 1380px) {
    .contacts--img {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 240px;
    }
    .contacts--container {
        padding: 2rem 4rem;
        width: 90%;
        margin-top: 1rem;
    }
}


@media screen and (max-width: 992px) {
    .contacts--img {
        display: none;
    }
    .contacts--container {
        width: 100%;
        margin-top: 1rem;
    }
    .contacts--container > h1 {
        font-size: 3rem;
        margin: 0 0 1.2rem 0;
    }
    .contacts-details {
        padding-left: 4rem;
    }
    .personal-details p {
        margin-left: 1rem;
        font-size: 1rem;
    }
}

@media screen and (max-width: 800px) {
    .contacts--container h1 {
        font-size: 3rem;
        margin-bottom: 30px;
    }
    .contacts--container {
        padding: 2rem;
        margin-top: 0rem;
        align-items: center;
    }
    .contacts-body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .contacts-form {
        display: flex;
        flex: 1 1;
        width: 80%;
    }
    
    .form-input {
        margin-bottom: 0.45rem;
    }
    .contacts-form form {
        align-items: flex-end;
    }
    .contacts-details {
        padding-left: 0rem;
        padding: 1rem;
        align-items: center;
        margin-top: 2rem;
    }
    .socialmedia-icons {
        width: 100%;
        grid-gap: 1.5rem;
        gap: 1.5rem;
        margin-top: 2rem;
    }
    .personal-details {
        margin-bottom: 1.8rem;
    }
    .personal-details p {
        margin-left: 1.2rem;
        width: 95%;
    }
}

@media screen and (max-width: 600px) {
    .contacts-form {
        display: flex;
        flex: 1 1;
        width: 100%;
    }
    .contacts--container h1 {
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 400px) {
    .contacts-details {
        padding-left: 0rem;
        padding: 0rem;
        align-items: center;
        margin-top: 2rem;
    }
    .submit-btn{align-self: center;}
}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
    .contacts-details {
        padding-left: 0rem;
        padding: 0rem;
    }
}





@-webkit-keyframes spin{
    0%{
        opacity: 1;
        -webkit-transform: translate(5px,-3px) rotate(0deg);
                transform: translate(5px,-3px) rotate(0deg);
    }
    /* 60%{
      opacity: 0;
    }
    80%{
      opacity: 1;
    } */
    100%{
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }





@keyframes spin{
    0%{
        opacity: 1;
        -webkit-transform: translate(5px,-3px) rotate(0deg);
                transform: translate(5px,-3px) rotate(0deg);
    }
    /* 60%{
      opacity: 0;
    }
    80%{
      opacity: 1;
    } */
    100%{
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }

  @-webkit-keyframes fly{
    /* 0%,100%{
        transform: translate(5px,-3px) rotate(-30deg);
        opacity: 1;
    }
    25%{
        transform: translate(15px,-10px) rotate(-30deg);
        opacity: 0.8;
    }
    50%{
        transform: translate(20px,-12px) rotate(-30deg);
        opacity: 0;
    }
    60%{
        transform: translate(-12px,10px) rotate(-30deg);
        opacity: 0;
    }
    75%{
        opacity: 0.8;
    } */
    10%{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        }
        20%{
        -webkit-transform: translateX(-10px);
                transform: translateX(-10px);    
        }
        70%{
          -webkit-transform: translateX(60px);
                  transform: translateX(60px);
        }
        100%{
            -webkit-transform: translateX(80px);
                    transform: translateX(80px);
        }
  }

  @keyframes fly{
    /* 0%,100%{
        transform: translate(5px,-3px) rotate(-30deg);
        opacity: 1;
    }
    25%{
        transform: translate(15px,-10px) rotate(-30deg);
        opacity: 0.8;
    }
    50%{
        transform: translate(20px,-12px) rotate(-30deg);
        opacity: 0;
    }
    60%{
        transform: translate(-12px,10px) rotate(-30deg);
        opacity: 0;
    }
    75%{
        opacity: 0.8;
    } */
    10%{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        }
        20%{
        -webkit-transform: translateX(-10px);
                transform: translateX(-10px);    
        }
        70%{
          -webkit-transform: translateX(60px);
                  transform: translateX(60px);
        }
        100%{
            -webkit-transform: translateX(80px);
                    transform: translateX(80px);
        }
  }
.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    overflow-x: hidden;
    padding: 2rem;
}


.projects--header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 1rem;
}


.projects--header h1 {
    margin-bottom: 40px;
    font-size: 3.5rem;
    font-family: var(--primaryFont);
}

.projects--body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.projects--bodyContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 4.5rem;
    gap: 4.5rem;
    width: 100%;
}

.projects--viewAll {
    width: 100%;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2rem;
}

.projects--viewAll a button {
    outline: none;
    border: none;
    width: 150px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: inherit;
    border-radius: 45px;
    font-size: 1.05rem;
    font-family: var(--primaryFont);
    font-weight: 500;
    padding-left: 1.5rem;
    cursor: pointer;
}



@media (min-width: 992px) and (max-width: 1380px) {

}


@media screen and (max-width: 992px) {
    .projects--bodyContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .projects--header h1 {
        font-size: 3rem;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 600px) {
    .projects--header h1 {
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
    .projects--viewAll {
        position: relative;
        margin-top: 4rem;
    }
    .projects--viewAll a {
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);  
    }
}

@media screen and (max-width: 400px) {

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}
.singleProject {
    box-shadow: 4px 4px 8px rgba(36, 3, 3, 0.2);
    width: 304px;
    height: 360px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.4rem 2rem;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.projectContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.projectContent > h2 {
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: 600;
    font-size: 1.525rem;
    line-height: 110%;
    text-align: center;
}

.projectContent > img {
    width: 100%;
    height: 100%;
    transition: opacity 0.7s 0.3s;
}

.singleProject:hover img{
    opacity: 0;
}

.project--showcaseBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}




.singleProject .project--desc{
    position: absolute;
    width: 95%;
    height: 220px;
    left: 0;
    top: 0;
    -webkit-transform: translate(-110%,40%);
            transform: translate(-110%,40%);
    padding: 15px;
    border-radius: 0 20px 20px 0;
    transition: -webkit-transform 0.9s;
    transition: transform 0.9s;
    transition: transform 0.9s, -webkit-transform 0.9s;
    line-height: 110%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--primaryFont);
}
  
.singleProject:hover .project--desc{
    -webkit-transform: translate(-2%,40%);
            transform: translate(-2%,40%);
}
  


.backToTop {
    position: fixed; 
    right: 10px;
    bottom: 60px;
    height: 30px;
    font-size: 3rem;
    z-index: 999;
}

.backToTop button {
    outline: none;
    border: none;
    cursor: pointer;
    background: none;
    padding: 20px;
}

@media screen and (max-width: 800px) {
    .backToTop { 
        right: -10px;
        bottom: 50px;
        font-size: 2.75rem;
    }
}
.blogPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
}


.blogPage--header {
    height: 35vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.blogPage--header h1 {
    font-size: 4rem;
    font-family: var(--primaryFont);
}

.blogPage--container {
    padding: 3rem 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}


.blog--search {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog--input {
    width: 30%;
    height: 2.75rem;
    outline: none;
    border: none;
    border-radius: 20px;
    padding: 0.25rem 1rem;
}

.blog--input::-webkit-input-placeholder {
    color: white;
}

.blog--input:-ms-input-placeholder {
    color: white;
}

.blog--input::placeholder {
    color: white;
}

.blogs--container {
    margin-top: 5rem;
    width: 100%;
}


.blog-grid {
    display: flex;
    grid-gap: 4rem;
    gap: 4rem;   
}



@media (min-width: 992px) and (max-width: 1380px) {

}


@media screen and (max-width: 992px) {
    .blogPage--header {
        height: 25vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .blog-grid {
        grid-gap: 3rem;
        gap: 3rem;   
    }
    .blog--input {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {

}

@media screen and (max-width: 702px) {
    .blog-grid {
        grid-gap: 1.5rem;
        gap: 1.5rem;
    }
}

@media screen and (max-width: 550px) {
    .blogPage--header h1 {
        font-size: 3rem;
    }
}
@media screen and (max-width: 400px) {
    .blogPage--header {
        height: 20vh;
    }
    .blogPage--header h1 {
        font-size: 2.5rem;
    }
}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}
.projectPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
}

.projectPage-header {
    height: 35vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.projectPage-header > h1 {
    font-size: 4rem;
    font-family: var(--primaryFont);
}

.projectPage-container {
    padding: 3rem 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.projectPage-search {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-container {
    margin-top: 5rem;
    width: 100%;
}

.project-grid {
    display: flex;
    grid-gap: 4rem;
    gap: 4rem; 
}

@media (min-width: 992px) and (max-width: 1380px) {

}

@media screen and (max-width: 992px) {
    .projectPage-header {
        height: 25vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .project-grid {
        grid-gap: 3rem;
        gap: 3rem;
    }
}

@media screen and (max-width: 702px) {
    .project-grid {
        grid-gap: 1.5rem;
        gap: 1.5rem;
    }
}

@media screen and (max-width: 550px) {
    .projectPage-header h1 {
        font-size: 3rem;
    }
}
@media screen and (max-width: 400px) {
    .projectPage-header {
        height: 20vh;
    }
    .projectPage-header h1 {
        font-size: 2.5rem;
    }
}
:root {
    --primaryFont: 'Poppins', sans-serif;
    /* --primaryFont: 'Montserrat', sans-serif; */
    /* --primaryFont: 'Raleway', sans-serif; */
}


