.education {
    min-height: 100vh;
}

.education-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: hsla(117, 56%, 49%, 0.702);
}

.education-description {
    width: 100%;
    flex: 0.65;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 7%;
}

.education-description > h1{
    font-size: 3.5rem;
    font-family: var(--primaryFont);
    margin-bottom: 2rem;
}

.education-card {
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    justify-content: flex-start;
    width: 90%;
    height: 140px;
    padding: 1.5rem;
    border-radius: 20px;
    margin-bottom: 1.5rem;
    transition: background-color 200ms ease-in-out;
}

.educard-img {
    border-radius: 50%;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.educard-img > img {
    width: 40px;
}

.education-details{
    margin-left: 0.6rem;
}

.education-details > h6 {
    font-family: var(--primaryFont);
    font-size: 0.85rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.education-details > h4 {
    font-family: var(--primaryFont);
    font-size: 1.225rem;
    font-weight: 600;
}

.education-details > h5 {
    font-family: var(--primaryFont);
    font-size: 1.15rem;
    font-weight: 600;
}

.education-image {
    box-sizing: border-box;
    padding: 1rem;
    flex: 0.35;
    margin-top: 1rem;
    pointer-events: none;
}

.education-image > img {
    width: 100%;
}



@media (min-width: 992px) and (max-width: 1380px) {

}


@media screen and (max-width: 992px) {
    .education {
        min-height: 100%;
    }
    .education-image {
        display: none;
    }
    .education-description {
        flex: 1;
        margin: auto;
    }    
    .education-description > h1{
        font-size: 3.2rem;
    }
    .education-card {
        width: 100%;
        padding: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

@media screen and (max-width: 800px) {
    .education-description > h1{
        font-size: 3rem;
        margin-bottom: 2rem;
        align-self: center;
    }
    .education-description {
        padding: 1rem;
    }
    .education-card {
        padding: 1rem;
        margin-bottom: 1.5rem;
        height: 130px;
    }
    .educard-img {
        border-radius: 50%;
        width: 45px;
        height: 45px;
    }
    
    .educard-img > img {
        width: 30px;
    }
    .education-details > h6 {
        font-size: 0.85rem;
        margin-bottom: 0.45rem;
    }
    
    .education-details > h4 {
        font-size: 1.125rem;
        line-height: 126%;
    }
    
    .education-details > h5 {
        font-size: 1.05rem;
        line-height: 126%;
    }
}

@media screen and (max-width: 600px) {
    .education-description > h1{
        font-size: 2.5rem;
    }
    .education-details > h4 {
        font-size: 1.12rem;
        line-height: 126%;
    }
    
    .education-details > h5 {
        font-size: 0.85rem;
        line-height: 126%;
    }
}

@media screen and (max-width: 400px) {
    .education-details > h4 {
        font-size: 1rem;
        line-height: 126%;
    }
    
    .education-details > h5 {
        font-size: 0.8rem;
        line-height: 126%;
    }

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}


@media screen and (max-width: 290px) {
    
    
    .education-details > h4 {
        font-size: 0.8rem;
        line-height: 126%;
    }
    
    .education-details > h5 {
        font-size: 0.6rem;
        line-height: 126%;
    }

}