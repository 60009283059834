.singleProject {
    box-shadow: 4px 4px 8px rgba(36, 3, 3, 0.2);
    width: 304px;
    height: 360px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.4rem 2rem;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.projectContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.projectContent > h2 {
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: 600;
    font-size: 1.525rem;
    line-height: 110%;
    text-align: center;
}

.projectContent > img {
    width: 100%;
    height: 100%;
    transition: opacity 0.7s 0.3s;
}

.singleProject:hover img{
    opacity: 0;
}

.project--showcaseBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}




.singleProject .project--desc{
    position: absolute;
    width: 95%;
    height: 220px;
    left: 0;
    top: 0;
    transform: translate(-110%,40%);
    padding: 15px;
    border-radius: 0 20px 20px 0;
    transition: transform 0.9s;
    line-height: 110%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--primaryFont);
}
  
.singleProject:hover .project--desc{
    transform: translate(-2%,40%);
}
  

